import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Web3 from 'web3';
import { 
    MY_ADDRESS, MY_LEDGER_ADDRESS, SINGULAR_ABI, ETH_BASIC_CONTRACT, 
    SINGULAR_CONTRACT_ADDRESS, XES_CONTRACT_ADDRESS, 
    FORKDELTA_CONTRACT_ADDRESS, INFURA_API_KEY, XES_ABI, 
    FORKDELTA_ABI, ETH_COUNT_DIGITS, CONI_HOLDINGS
} from './../config' 

interface Coin 
{
    available: string, 
    onOrders: string
}

interface CoinData {
    CoinName: string,
    CoinDetails: Coin
}

interface MyProps {
    
}
  
interface MyState {
    error: {message: string} | null,
    isLoaded: boolean,
    items: CoinData[],
    account: String,
    IDEXETHBalance: String,
    IDEXXESBalance: String,
    SingularXESBalance: String,
    SingularETHBalance: String,
    MetaMaskBalance: String,
    MetaMaskXESBalance: String,
    ForkdeltaXESBalance: String,
    ForkdeltaETHBalance: String,
    LedgerETHBalance: String,
    ETHPrice: Number,
    XESPrice: Number,
}

class IdexBalance extends React.Component<MyProps, MyState> {

    state: MyState ={
        error: null,
        isLoaded: false,
        items: [],
        account: '',
        IDEXETHBalance: '',
        IDEXXESBalance: '',
        SingularXESBalance: '',
        SingularETHBalance: '',
        MetaMaskBalance: '',
        MetaMaskXESBalance: '',
        ForkdeltaXESBalance: '',
        ForkdeltaETHBalance: '',
        LedgerETHBalance: '',
        ETHPrice: 0,
        XESPrice: 0,
    }

    async loadBlockchainData() {
        const infura = `https://mainnet.infura.io/v3/${INFURA_API_KEY}`;
        const web3 = new Web3(new Web3.providers.HttpProvider(infura))
        //const accounts = await web3.eth.getAccounts()
        //this.setState({ account: accounts[0] })
        const SingularContract = new web3.eth.Contract(
            JSON.parse(SINGULAR_ABI), 
            SINGULAR_CONTRACT_ADDRESS
        )
        const XESContract = new web3.eth.Contract(
            JSON.parse(XES_ABI), 
            XES_CONTRACT_ADDRESS
        )
        const ForkdeltaContract = new web3.eth.Contract(
            JSON.parse(FORKDELTA_ABI),
            FORKDELTA_CONTRACT_ADDRESS
        )
        let SingularXESBalance = await SingularContract.methods.balanceOf(XES_CONTRACT_ADDRESS, MY_ADDRESS).call()
        this.setState({ SingularXESBalance: this.parseNumber((SingularXESBalance / ETH_COUNT_DIGITS).toString()) })
        let SingularETHBalance = await SingularContract.methods.balanceOf(ETH_BASIC_CONTRACT, MY_ADDRESS).call()
        this.setState({ SingularETHBalance: this.parseNumber((SingularETHBalance / ETH_COUNT_DIGITS).toString()) })
        
        let MetaMaskBalance: any =  await web3.eth.getBalance(MY_ADDRESS)
        this.setState({ MetaMaskBalance: this.parseNumber((MetaMaskBalance / ETH_COUNT_DIGITS).toString()) })
        let MetaMaskXESBalance = await XESContract.methods.balanceOf(MY_ADDRESS).call()
        this.setState({ MetaMaskXESBalance: this.parseNumber((MetaMaskXESBalance / ETH_COUNT_DIGITS).toString()) })
        
        let ForkdeltaXESBalance = await ForkdeltaContract.methods.balanceOf(XES_CONTRACT_ADDRESS, MY_ADDRESS).call()
        this.setState({ ForkdeltaXESBalance: this.parseNumber((ForkdeltaXESBalance / ETH_COUNT_DIGITS).toString()) })
        let ForkdeltaETHBalance = await ForkdeltaContract.methods.balanceOf(ETH_BASIC_CONTRACT, MY_ADDRESS).call()
        this.setState({ ForkdeltaETHBalance: this.parseNumber((ForkdeltaETHBalance / ETH_COUNT_DIGITS).toString()) })

        let LedgerETHBalance: any =  await web3.eth.getBalance(MY_LEDGER_ADDRESS)
        this.setState({ LedgerETHBalance: this.parseNumber((LedgerETHBalance / ETH_COUNT_DIGITS).toString()) })
    }

    parseNumber(numberval: string){
        return Number.parseFloat(numberval).toFixed(2)
    }
    parseNumberT(numberval: string){
        return Number.parseFloat(numberval).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, "'")
    }
    /*requestOptions = {
            method: ‘GET’,
            uri: ‘https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest&#8217;,
            qs: {
            start: 1,
            limit: 5000,
            convert: ‘USD’
            },
            headers: {
                ‘X-CMC_PRO_API_KEY’: apiKey
            },
            json: true,
            gzip: true
            };*/
    componentDidMount() {
        this.loadBlockchainData()
        fetch(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD`, 
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                //mode: 'no-cors',
            })
            .then(res => res.json())
            .then(
            (result) => {
                if (result != null) {
                    this.setState({
                        isLoaded: true,
                        ETHPrice: result.ethereum.usd,
                    });
                }
            },
            (error) => {
                this.setState({
                isLoaded: true,
                error
                });
            }
            )
        fetch(`https://api.coingecko.com/api/v3/simple/price?ids=proxeus&vs_currencies=USD`, 
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                //mode: 'no-cors',
            })
            .then(res => res.json())
            .then(
            (result) => {
                if (result != null) {
                    this.setState({
                        isLoaded: true,
                        XESPrice: result.proxeus.usd,
                    });
                }
            },
            (error) => {
                this.setState({
                isLoaded: true,
                error
                });
            }
            )
        fetch(`https://api.idex.market/returnCompleteBalances?address=${MY_ADDRESS}`)
            .then(res => res.json())
            .then(
            (result) => {
                Object.keys(result).map((coin) => {
                    const CoinInfo = result[coin]
                    const available = this.parseNumber(CoinInfo.available)
                    const onOrders = this.parseNumber(CoinInfo.onOrders)
                    if (coin === "XES"){
                        this.setState({
                            IDEXXESBalance:(+available + +onOrders).toString()
                            })
                    }
                    if (coin === "ETH"){
                        this.setState({
                            IDEXETHBalance:(+available + +onOrders).toString()
                            })
                    }
                }, [])
                this.setState({
                isLoaded: true,
                items: result
                });
            },
            (error) => {
                this.setState({
                isLoaded: true,
                error
                });
            }
            )
        
       
    }
    
    render() {
        const { 
            error, 
            isLoaded, 
            items, 
            account, 
            SingularXESBalance, 
            SingularETHBalance, 
            MetaMaskBalance, 
            MetaMaskXESBalance, 
            ForkdeltaETHBalance, 
            ForkdeltaXESBalance, 
            IDEXETHBalance, 
            IDEXXESBalance,
            LedgerETHBalance,
            ETHPrice,
            XESPrice,
        } = this.state;
        if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <div>Loading...</div>;
        } else {
          return (   
            //<Web3ReactProvider getLibrary={getLibrary}>
            //<p>local account info: {account}</p>
                <div>
                    <h3>Overall Balance</h3>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell>Coin</TableCell>
                                <TableCell align="right">Sum Dollar</TableCell>
                                <TableCell align="right">Sum Balance</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">ETH @ {ETHPrice} $</TableCell>
                                    <TableCell align="right">{this.parseNumberT(((+SingularETHBalance+ +IDEXETHBalance + +MetaMaskBalance + +LedgerETHBalance + +ForkdeltaETHBalance)*+ETHPrice).toString()).toString()} $</TableCell>
                                    <TableCell align="right">{this.parseNumberT((+SingularETHBalance+ +IDEXETHBalance + +MetaMaskBalance + +LedgerETHBalance + +ForkdeltaETHBalance).toString()).toString()} ETH</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">XES @ {XESPrice} $</TableCell>
                                    <TableCell align="right">{this.parseNumberT(((+SingularXESBalance + +IDEXXESBalance + +MetaMaskXESBalance + +ForkdeltaXESBalance - +CONI_HOLDINGS)*+XESPrice).toString()).toString()} $</TableCell>
                                    <TableCell align="right">{this.parseNumberT((+SingularXESBalance + +IDEXXESBalance + +MetaMaskXESBalance + +ForkdeltaXESBalance - +CONI_HOLDINGS).toString()).toString()} XES</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Total $</TableCell>
                                    <TableCell align="right"><b>{this.parseNumberT((((+SingularXESBalance + +IDEXXESBalance + +MetaMaskXESBalance + +ForkdeltaXESBalance - +CONI_HOLDINGS)*+XESPrice)+((+SingularETHBalance+ +IDEXETHBalance + +MetaMaskBalance + +LedgerETHBalance + +ForkdeltaETHBalance)*+ETHPrice)).toString()).toString()} $</b></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h3>Singular Balance</h3>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell>Coin</TableCell>
                                <TableCell align="right">Sum Balance</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">ETH</TableCell>
                                    <TableCell align="right">{SingularETHBalance}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">XES</TableCell>
                                    <TableCell align="right">{SingularXESBalance}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h3>MetaMask Balance</h3>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell>Coin</TableCell>
                                <TableCell align="right">Sum Balance</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">ETH</TableCell>
                                    <TableCell align="right">{MetaMaskBalance}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">XES</TableCell>
                                    <TableCell align="right">{MetaMaskXESBalance}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h3>Ledger Balance</h3>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell>Coin</TableCell>
                                <TableCell align="right">Sum Balance</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">ETH</TableCell>
                                    <TableCell align="right">{LedgerETHBalance}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h3>IDEX Balance</h3>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell>Coin</TableCell>
                                <TableCell align="left">Available / On Orders</TableCell>
                                <TableCell align="right">Sum Balance</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.keys(items).map((coin) => {
                                        const CoinInfo = items[coin]
                                        const available = this.parseNumberT(CoinInfo.available)
                                        const onOrders = this.parseNumberT(CoinInfo.onOrders)
                                        const Sum = this.parseNumberT((+CoinInfo.available + +CoinInfo.onOrders).toString())
                                        return <TableRow key={coin}>
                                        <TableCell align="left">
                                            {coin}
                                        </TableCell>
                                        <TableCell align="left">
                                        {available} / {onOrders}
                                        </TableCell>
                                        <TableCell align="right">
                                            {Sum}
                                        </TableCell>
                                    </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    <h3>Forkdelta Balance</h3>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell>Coin</TableCell>
                                <TableCell align="right">Sum Balance</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">ETH</TableCell>
                                    <TableCell align="right">{ForkdeltaETHBalance}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">XES</TableCell>
                                    <TableCell align="right">{ForkdeltaXESBalance}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
            </div>
          //</Web3ReactProvider>
          )
        }
      }
  }

  export default IdexBalance;